import $ from "jquery";
// $ = window.jQuery;

// bootstrap
import "bootstrap-sass/assets/javascripts/bootstrap/transition.js";
import "bootstrap-sass/assets/javascripts/bootstrap/affix.js";
import "bootstrap-sass/assets/javascripts/bootstrap/alert.js";
import "bootstrap-sass/assets/javascripts/bootstrap/dropdown.js";
import "bootstrap-sass/assets/javascripts/bootstrap/tooltip.js";
import "bootstrap-sass/assets/javascripts/bootstrap/modal.js";
import "bootstrap-sass/assets/javascripts/bootstrap/button.js";
import "bootstrap-sass/assets/javascripts/bootstrap/popover.js";
import "bootstrap-sass/assets/javascripts/bootstrap/scrollspy.js";
import "bootstrap-sass/assets/javascripts/bootstrap/collapse.js";
import "bootstrap-sass/assets/javascripts/bootstrap/tab.js";

var bodyToggle = $("body");
var navMobile = $(".js--nav-toggle");
var navMobileButton = $(".js--nav-button");
var searchMobile = $(".js--search-toggle");
var searchMobileButton = $(".js--search-button");

if (window.matchMedia("(max-width:895px)").matches) {
    mobileView();
} else if (window.matchMedia("(min-width:768px)").matches) {
    desktopView();
}

window.matchMedia("(max-width:895px)").addEventListener("change", function (event) {
    if (event.matches) {
        mobileView();
    }
});

window.matchMedia("(min-width:768px)").addEventListener("change", function (event) {
    if (event.matches) {
        desktopView();
    }
});

function mobileView() {
    navMobileButton.click(function () {
        searchMobile.removeClass("showit");
        bodyToggle.toggleClass("noscroll");
        navMobile.toggleClass("showit");
    });

    searchMobileButton.click(function () {
        navMobile.removeClass("showit");
        bodyToggle.toggleClass("noscroll");
        searchMobile.toggleClass("showit");
    });
}

function desktopView() {
    $(".search-form__button").click(function (event) {
        if (!$(".js--search-toggle").hasClass("showit")) {
            event.preventDefault();
        }
    });

    $(".search-form").click(function () {
        event.stopPropagation();
        $(".js--search-toggle").addClass("showit");
        $(".search-form__input").focus();
        $(".nav").addClass("hide");
        $(".nav-main__callout").addClass("hide");
    });

    $(".search-form__close").click(function (event) {
        event.stopPropagation();
        $(".nav").removeClass("hide");
        $(".nav-main__callout").removeClass("hide");
        $(".js--search-toggle").removeClass("showit");
    });

    var $opps = $(".opportunities-item__link");
    var oppUrl = "";
    var $thisOpp;
    var oppTitleString = "";
    var oppHtmlString = "";
    var $domInsert = $("#main");

    $opps.click(function (e) {
        e.preventDefault();

        $(".opportunities__item.active").removeClass("active");

        $thisOpp = $(this);
        $thisOpp.parent().addClass("active");
        oppUrl = $thisOpp.attr("href");
        $.get(oppUrl, function (data) {
            oppTitleString = data;
            oppTitleString = oppTitleString.split("<title>")[1];
            oppTitleString = oppTitleString.split("</title>")[0];
            oppHtmlString = data;
            oppHtmlString = oppHtmlString.split("<article")[1];
            oppHtmlString = oppHtmlString.split("</article>")[0];
            oppHtmlString = "<article" + oppHtmlString + "</article>";
            $("article.article--opportunity").remove();
            $domInsert.append(oppHtmlString);
            history.pushState(null, null, oppUrl);
            $("article.article--opportunity").css({ opacity: 0 });
            $("article.article--opportunity").animate({ opacity: 1 }, "slow");
            $("html, body").animate({ scrollTop: 0 }, "fast");
            document.title = oppTitleString.trim();
        });
    });
}

var $oppsFilterWrapper = $(".opportunities__categories");
var $oppsFilters = $(".opportunities__category");
var oppsFilterCategory = "";
var $thisFilter;
var $oppsListItems = $(".opportunities__item");
var $oppsListItemsTopics = $(".opportunities-item__topic");
var $oppsListItemsFiltered;

$oppsFilters.click(function (e) {
    e.preventDefault();

    $thisFilter = $(this);
    oppsFilterCategory = $thisFilter.attr("data-tag");

    $(".opportunities__categories--button--close").click(function (event) {
        event.stopPropagation();
        bodyToggle.removeClass("noscroll");
        $oppsFilterWrapper.removeClass("showit");

        return;
    });

    $oppsListItems.show();

    if (window.matchMedia("(max-width:895px)").matches) {
        if ($thisFilter.hasClass("opportunities__category--active")) {
            bodyToggle.toggleClass("noscroll");
            $oppsFilterWrapper.toggleClass("showit");
            return;
        }

        bodyToggle.removeClass("noscroll");
        $oppsFilterWrapper.removeClass("showit");
        $oppsListItems.addClass("mobile--filtered");
    }
    window.matchMedia("(max-width:895px)").addEventListener("change", function (event) {
        if (event.matches) {
            if ($thisFilter.hasClass("opportunities__category--active")) {
                bodyToggle.toggleClass("noscroll");
                $oppsFilterWrapper.toggleClass("showit");
                return;
            }

            bodyToggle.removeClass("noscroll");
            $oppsFilterWrapper.removeClass("showit");
            $oppsListItems.addClass("mobile--filtered");
        }
    });

    $(".opportunities").css({ opacity: 0 });
    $oppsFilters.removeClass("opportunities__category--active");
    $oppsListItemsFiltered = $(".opportunities__item--" + oppsFilterCategory);
    $oppsListItems.hide();
    $oppsListItemsFiltered.show();
    $thisFilter.addClass("opportunities__category--active");

    if (oppsFilterCategory === "all") {
        $oppsListItems.removeClass("mobile--filtered");
        $oppsListItems.show();
    }
    $(".opportunities").animate({ opacity: 1 }, "slow");
});
